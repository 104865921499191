import config from 'config';
import React, { FunctionComponent, memo, useMemo } from 'react';
import { Helmet } from 'react-helmet';

type Props = {
  title?: string;
  description?: string;
  keywords?: string;
  type?: string;
  name?: string;
};

const AppHelmet: FunctionComponent<Props> = props => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { title, description = '', keywords, type = '', name = '' } = props;
  const SEOKeywords = useMemo(() => keywords || 'HST, manager', [keywords]);
  const FacebookDomainVerificationCode = useMemo(() => '36wh0henmjj0mt74lo8ynw60cab51c', []);
  const pageTitle = title ? config.appName + ' - ' + title : config.appName;

  return (
    <Helmet>
      <title>{pageTitle}</title>
      <meta name="facebook-domain-verification" content={FacebookDomainVerificationCode} />
      <meta name="description" content={description} />
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="robots" content="index, follow" />
      <meta key="googlebot" name="googlebot" content="index,follow" />
      <meta name="google" content="notranslate" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="keywords" content={SEOKeywords} />
    </Helmet>
  );
};

export default memo(AppHelmet);

import { makeAutoObservable } from 'mobx';
import { TCurrentAccount } from '@features/auth/types';

export class AccountInformationStore {
  accountInformation: TCurrentAccount = {} as TCurrentAccount;
  myPermission: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setAccountInformation = (accountInformation: TCurrentAccount) => {
    this.accountInformation = accountInformation;
  };

  setMyPermission = (myPermission: string[]) => {
    this.myPermission = myPermission;
  };
}

const accountInformationStore = new AccountInformationStore();

export default accountInformationStore;

import { memo, useCallback } from 'react';
import { Button, Modal } from 'antd';
import WarningOutlined from '@ant-design/icons/WarningOutlined';

type TProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const OldVersionWarningModal = ({ isOpen, setIsOpen }: TProps) => {
  const handleCancel = useCallback(() => setIsOpen(false), [setIsOpen]);

  return (
    <Modal
      title={
        <div className="flex items-center gap-2.5 font-bold ">
          <WarningOutlined className="text-red-500" />
          Cảnh báo
        </div>
      }
      open={isOpen}
      onCancel={handleCancel}
      footer={null}
      maskClosable={false}
      closable={false}
      centered={true}
    >
      <p>
        Bạn đang làm việc trên phiên bản cũ. Vui lòng xóa cache trình duyệt để cập nhật phiên bản
        mới.
      </p>

      <div className="text-end">
        <Button type="primary" onClick={() => handleCancel()}>
          OK
        </Button>
      </div>
    </Modal>
  );
};

export default memo(OldVersionWarningModal);

import { makeAutoObservable } from 'mobx';
import { TSelectedPartnerAndService } from '@features/partners/types';

export class SelectedBusinessPartnerAndServiceStore {
  selectedBusinessPartnerAndService = {} as TSelectedPartnerAndService;

  constructor() {
    makeAutoObservable(this);
  }

  setSelectedBusinessPartnerAndService = (
    selectedBusinessPartnerAndService: TSelectedPartnerAndService
  ) => {
    this.selectedBusinessPartnerAndService = selectedBusinessPartnerAndService;
  };

  resetSelectedBusinessPartnerAndService = () => {
    this.selectedBusinessPartnerAndService = {} as TSelectedPartnerAndService;
  };
}

const selectedBusinessPartnerAndServiceStore = new SelectedBusinessPartnerAndServiceStore();

export default selectedBusinessPartnerAndServiceStore;

const config = {
  mode: process.env.NODE_ENV || 'development',
  appName: process.env.REACT_APP_NAME || 'HST Manager',
  apiUrl: process.env.REACT_APP_API_URL,
  apiKeyGoogleMap: process.env.REACT_APP_API_KEY_GOOGLE_MAP,
  mapId: process.env.REACT_APP_MAP_ID,
  /** AppMode. Internal = metatrip, external = vietan */
  appMode: process.env.REACT_APP_MODE || 'metatrip',
};

export default config;

import { makeAutoObservable } from 'mobx';
import { TAppSettings } from '@features/appSettings/types';

export class AppSettingsStore {
  appSettings: TAppSettings = {} as TAppSettings;

  constructor() {
    makeAutoObservable(this);
  }

  setAppSettings = (appSettings: TAppSettings) => {
    this.appSettings = appSettings;
  };
}

const appSettingsStore = new AppSettingsStore();

export default appSettingsStore;

import { makeAutoObservable } from 'mobx';

export class CommonStore {
  constructor() {
    makeAutoObservable(this);
  }

  appTheme = '#171721';
  brandColor = '#be3491';

  isSidebarCollapsed = false;
  toggleSidebarCollapsed = () => {
    this.isSidebarCollapsed = !this.isSidebarCollapsed;
  };
  setSidebarCollapsedState = (state: boolean) => {
    this.isSidebarCollapsed = state;
  };
}

const commonStore = new CommonStore();
export default commonStore;

import amusementParkTicketDataStore, {
  AmusementParkTicketDataStore,
} from '@/stores/amusementParkTicketDataStore';
import destinationStore, { DestinationStore } from '@/stores/destinationStore';
import hotelStore, { HotelStore } from '@/stores/hotelStore';
import insuranceDataStore, { InsuranceDataStore } from '@/stores/insuranceDataStore';
import partnerStore, { PartnerStore } from '@/stores/partnerStore';
import selectedBusinessPartnerAndServiceStore, {
  SelectedBusinessPartnerAndServiceStore,
} from '@/stores/selectedBusinessPartnerAndServiceStore';
import selectedWebPartnerAndServiceStore, {
  SelectedWebPartnerAndServiceStore,
} from '@/stores/selectedWebPartnerAndServiceStore';
import siteDataStore, { SiteDataStore } from '@/stores/siteDataStore';
import accountInformationStore, { AccountInformationStore } from './accountInformationStore';
import airportDataStore, { AirportDataStore } from './airportDataStore';
import commonStore, { CommonStore } from './commonStore';
import flightDataStore, { FlightDataStore } from './flightDataStore';
import placeDataStore, { PlaceDataStore } from './placeDataStore';
import serviceDataStore, { ServiceDataStore } from './serviceDataStore';
import appSettingsStore, { AppSettingsStore } from '@/stores/appSettingsStore';

export type RootStore = {
  commonStore: CommonStore;
  airportDataStore: AirportDataStore;
  accountInformationStore: AccountInformationStore;
  serviceDataStore: ServiceDataStore;
  flightDataStore: FlightDataStore;
  partnerStore: PartnerStore;
  selectedWebPartnerAndServiceStore: SelectedWebPartnerAndServiceStore;
  selectedBusinessPartnerAndServiceStore: SelectedBusinessPartnerAndServiceStore;
  destinationStore: DestinationStore;
  hotelStore: HotelStore;
  siteDataStore: SiteDataStore;
  amusementParkTicketDataStore: AmusementParkTicketDataStore;
  insuranceDataStore: InsuranceDataStore;
  placeDataStore: PlaceDataStore;
  appSettingsStore: AppSettingsStore;
};

const rootStore: RootStore = {
  commonStore,
  airportDataStore,
  accountInformationStore,
  serviceDataStore,
  flightDataStore,
  partnerStore,
  selectedWebPartnerAndServiceStore,
  selectedBusinessPartnerAndServiceStore,
  destinationStore,
  hotelStore,
  siteDataStore,
  amusementParkTicketDataStore,
  insuranceDataStore,
  placeDataStore,
  appSettingsStore,
};

export default rootStore;

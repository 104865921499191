import { ORDER_TYPE, SORT_BY } from '@features/insurance/constants';
import {
  TInsuranceSearchPayload,
  TInsuranceSearchResponse,
  TPolicyData,
} from '@features/insurance/types';
import { makeAutoObservable } from 'mobx';

export class InsuranceDataStore {
  insuranceFilter: TInsuranceSearchPayload = {
    orderType: ORDER_TYPE.DESCENDING,
    sortBy: SORT_BY.PRICE,
  } as TInsuranceSearchPayload;
  insuranceData: TInsuranceSearchResponse = {} as TInsuranceSearchResponse;
  selectedInsurance: TInsuranceSearchResponse = {} as TInsuranceSearchResponse;
  policy: TPolicyData = {} as TPolicyData;

  constructor() {
    makeAutoObservable(this);
  }

  setInsuranceFilter = (insuranceFilter: TInsuranceSearchPayload) => {
    this.insuranceFilter = insuranceFilter;
  };

  setInsuranceData = (insuranceData: TInsuranceSearchResponse) => {
    this.insuranceData = insuranceData;
  };

  setSelectedInsurance = (selectedInsurance: TInsuranceSearchResponse) => {
    this.selectedInsurance = selectedInsurance;
  };

  setPolicy = (policy: TPolicyData) => {
    this.policy = policy;
  };
}

const insuranceDataStore = new InsuranceDataStore();

export default insuranceDataStore;

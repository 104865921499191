import React, { FC, PropsWithChildren, useEffect } from 'react';

const ScrollRestoration: FC<PropsWithChildren> = props => {
  useEffect(() => {
    const elm = document.getElementById('main');
    if (elm) {
      elm.scrollTop = 0;
    }
  }, []);

  return <>{props.children}</>;
};

export default ScrollRestoration;

import React from 'react';
import style from './SplashScreen.module.scss';
import clsx from 'clsx';

type Props = {
  fullscreen?: boolean;
};

const dotStyle = "block content-[''] w-2 h-2 rounded-full bg-[var(--color-primary)]";

const SplashScreen = ({ fullscreen }: Props) => {
  return (
    <div
      className={clsx(
        'flex items-center justify-center w-full h-full',
        fullscreen && 'fixed top-0 left-0 h-screen w-screen z-40'
      )}
    >
      <div className="flex items-center justify-center">
        <span className={style.title}>
          <span className={dotStyle}></span>
        </span>
        <span className={style.title}>
          <span className={dotStyle}></span>
        </span>
        <span className={style.title}>
          <span className={dotStyle}></span>
        </span>
        <span className={style.title}>
          <span className={dotStyle}></span>
        </span>
        <span className={style.title}>
          <span className={dotStyle}></span>
        </span>
      </div>
    </div>
  );
};

export default SplashScreen;

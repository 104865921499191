import { TPlace, TSearchPlacePayload } from '@features/place/types';
import { makeAutoObservable } from 'mobx';

export class PlaceDataStore {
  filter: TSearchPlacePayload = {} as TSearchPlacePayload;
  placeData: TPlace[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setFilter = (filter: TSearchPlacePayload) => {
    this.filter = filter;
  };

  setPlaceData = (placeData: TPlace[]) => {
    this.placeData = placeData;
  };
}

const placeDataStore = new PlaceDataStore();

export default placeDataStore;
